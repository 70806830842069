/* Large devices (desktops, larger than 1200px) */
@media only screen and (min-width: 1200px) {
  .bilan-title {
    font-family: 'NotoSansCham';
    font-size: 1.5rem;
  }

  .bilan-latin-title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .ikasSarak-title {
    font-family: 'AkharTakai';
    font-size: 2rem;
    margin-bottom: 0;
  }

  .gregory-date {
    font-size: 0.8rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .gregory-date.active {
    font-size: 1rem;
    font-weight: 500;
  }

  .ahier-date {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 0.1rem;
  }

  .awal-date {
    font-size: 1rem;
    padding-top: 2rem;
    padding-bottom: 0.3rem;
    padding-right: 0.3rem;
  }

  .ahier-date.active,
  .awal-date.active {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .notice .ahier-date,
  .notice .awal-date {
    font-size: 1.2rem;
  }

  .ikasSarak-month-cell {
    font-size: 1rem;
  }

  .ikasSarak-month-cell.active {
    font-size: 1.2rem;
  }

  .event-name {
    display: block;
    font-size: 0.7rem;
    color: white;
    background-color: #3788d8;
    padding-left: 0.3rem;
    text-align: left;
    margin-top: 0.1rem;
  }

  .event-cham-name {
    font-family: 'NotoSansCham';
    font-size: 1.0rem;
  }
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 992px) {
  /* body {
    background-color: blue;
  } */
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 768px) {
  /* body {
    background-color: purple;
  } */
}

/* Extra small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 576px) {
  /* body {
    background-color: yellow;
  } */

  .bilan-title {
    font-family: 'NotoSansCham';
    font-size: 1rem;
    margin-bottom: -10px;
  }

  .bilan-latin-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .ikasSarak-title {
    font-family: 'AkharTakai';
    font-size: 1.5rem;
  }

  .day-name {
    font-size: 0.5rem;
  }

  .gregory-date {
    font-size: 0.5rem;
    /* padding-top: 0.1rem;
    padding-right: 1rem;
    padding-bottom: 0.1rem; */
  }

  .gregory-date.active {
    font-size: 0.6rem;
    font-weight: bold;
  }

  .ahier-date {
    font-size: 0.5rem;
    /* padding-top: 0rem;
    padding-bottom: 0rem; */
  }

  .awal-date {
    font-size: 0.5rem;
    /* padding-top: 1rem;
    padding-bottom: 0.3rem;
    padding-right: 0.3rem; */
  }

  .ahier-date.active,
  .awal-date.active {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .notice .ahier-date,
  .notice .awal-date {
    font-size: 1rem;
  }

  .ikasSarak-month-cell {
    font-size: 0.5rem;
  }

  .ikasSarak-month-cell.active {
    font-size: 0.8rem;
  }

  .event-name {
    display: block;
    font-size: 0.4rem;
    color: white;
    background-color: #3788d8;
    padding-left: 0.3rem;
    text-align: left;
    margin-top: 0.1rem;
  }

  .event-cham-name {
    font-family: 'NotoSansCham';
    font-size: 0.8rem;
  }
}

.table td,
.table th {
  padding-top: .1rem;
  padding-right: .3rem;
  padding-bottom: .1rem;
  padding-left: .3rem;
}

.progress {
  height: 1.5rem;
  font-size: 1rem;
  margin-bottom: 2px;
}

.progress-bar {
  text-align: left;
  padding-left: 3px;
}

.logo {
  font-family: 'NotoSansCham';
  font-weight: 500;
  font-size: 1.8rem;
}

.branding-text {
  font-weight: 450;
  font-size: 1.5rem;
}

.ahier-date {
  font-family: 'NotoSansCham';
  color: #F15A25;
  text-align: right;
}

.awal-date {
  font-family: 'NotoSansCham';
  flex-direction: row;
  color: #007A3D;
  align-self: end;
}

.ahier-date.display-latin-number,
.awal-date.display-latin-number {
  font-family: var(--bs-body-font-family);
}

.gregory-date {
  color: black;
  text-align: right;
}

.ikasSarak-month-cell {
  font-family: 'AkharTakai';
}

.circle-event-type {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.day-name {
  text-align: center;
}

.day-name:first-child {
  color: red;
}

@font-face {
  font-family: 'NotoSansCham';
  src: url('fonts/NotoSansCham-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AkharTakai';
  src: url('fonts/Akhar-Takai.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}